// Imports
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital@0;1&display=swap');

* {
  font-family: 'Ubuntu';
}

html, body, #root {
  height: 100%;
}
